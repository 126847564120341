<h2 mat-dialog-title>Add Services</h2>
<form [formGroup]="addPriceForm">
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Title</mat-label>
                    <input matInput placeholder="Enter Price" minlength="2" maxlength="30" formControlName="title">
                </mat-form-field>
            </div>
            <div class="col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Enter Price" minlength="2" maxlength="130"
                        formControlName="description">
                </mat-form-field>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-10">
                        <mat-form-field appearance="outline">
                            <mat-label>Supported Formats:- PNG,JPEG,JPG</mat-label>
                            <input matInput placeholder="Supported Formats:- PNG,JPEG,JPG" readonly
                                formControlName="image">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-element">
                            <button mat-raised-button color="primary" class="banner-upload upload-btn" type="button">
                                <mat-icon>cloud_upload</mat-icon>
                                Upload
                                <input type="file" class="browse-file" (change)="uploadFile($event,'banner')">
                                <span class="uploadFile"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="saveData()" tabindex="1">Submit </button>
        <button mat-raised-button color="warn" [mat-dialog-close] tabindex="-1">Cancel</button>
    </mat-dialog-actions>
</form>