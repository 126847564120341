import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { EmployeeModalComponent } from './employee-modal/employee-modal.component';
import { UsersService } from '../../services/users.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit, OnDestroy {
  public subject: Subject<string> = new Subject();
  displayedColumns: string[] = ['position', 'username', 'phone', 'email', 'role', 'action'];
  dataSource!: MatTableDataSource<PeriodicElement>;
  subscriptions: Subscription[] = [];
  customerRequests: any;
  allParams: any = {};
  errors: any;
  results: any;
  totalRecords = 0;
  pageNumbers = 0;
  pageLimits = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    public dialog: MatDialog,
    private userService: UsersService,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {

    this.allParams = {
      pageNum: 1,
      pageLimit: 10
    };

    this.allEmployees();

    this.subject.pipe(
      debounceTime(500),
      switchMap(value => this.userService.getRequest(this.allParams)),
    ).subscribe(
      result => {
        this.results = result;
        if (result) {
          this.dataSource = new MatTableDataSource(this.results.items);
          this.totalRecords = this.results.total;
          this.pageNumbers = this.results.pageNumber;
          this.pageLimits = this.results.pageLimit;
          this.spinner.hide();
        }
      },
      e => {
        this.spinner.hide();
        return this.errors = e.error;
      }
    );

  }

  allEmployees(): void {
    this.subscriptions.push(this.userService.getEmployees(this.allParams).subscribe(result => {
      this.results = result;
      this.spinner.hide();
      this.customerRequests = this.results;
      this.totalRecords = this.results.total;
      this.pageNumbers = this.results.pageNumber;
      this.pageLimits = this.results.pageLimit;
      this.dataSource = new MatTableDataSource(this.customerRequests.items);
      this.dataSource.paginator = this.paginator;
    },
      e => {
        console.log(e);
        return this.errors = e.error;
      }));
  }

  addUser(id: string) {
    const dialogRef = this.dialog.open(EmployeeModalComponent, {
      // width: '35vw',
      // maxWidth: '100vw',
      data: { userId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.allEmployees();
      if (result.success && result.isEdit) {
        return this.notifyService.showSuccess('User update successfully!!', 'Register');
      }
      return result.success && !result.isEdit ? this.notifyService.showSuccess('Register successfully!!', 'Register')
        : this.notifyService.showWarning('Register Failed!!', 'Register');
    });
  }

  edit(id: string): void {
    this.addUser(id);
  }

  delete(id: string): void {
    this.subscriptions.push(this.userService.deleteEmployee(id).subscribe((result: any) => {
      this.allEmployees();
      return result.success ? this.notifyService.showSuccess('Delete successfully!!', 'Star Wedding')
        : this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
    },
      e => {
        console.log(e);
        this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
        return this.errors = e.error;
      }));
  }

  pageChanged(e: any): void {
    this.allParams.pageNum = e.pageIndex + 1;
    this.allParams.pageLimit = e.pageSize;
    this.allEmployees();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
