<h3>Edit Student Details</h3>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="editForm" >
        <div>
            <h2>Register</h2>
        </div>
        <div>
            <mat-form-field >
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter Name" formControlName="name">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field >
                <mat-label>Class</mat-label>
                <input matInput placeholder="Enter Name" formControlName="class">
            </mat-form-field>
        </div>
     
        <div>
            <mat-form-field >
                <mat-label>Phone</mat-label>
                <input matInput placeholder="Enter Name" formControlName="phone">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field >
                <mat-label>Email</mat-label>
                <input matInput placeholder="Enter Email" formControlName="email">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" type="password" formControlName="password">
            </mat-form-field>
        </div>
        <div>
            <button type="submit" mat-raised-button color="primary">Register</button>
        </div>
    </form>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions>
