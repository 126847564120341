import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PeriodicElement } from 'src/app/cms/modal-dialog/modal-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { AddServicesComponent } from './add-services/add-services.component';
import { EditModelComponent } from './edit-model/edit-model.component';

@Component({
  selector: 'app-service-gallery',
  templateUrl: './service-gallery.component.html',
  styleUrls: ['./service-gallery.component.scss']
})
export class ServiceGalleryComponent implements OnInit, OnDestroy {
  public subject: Subject<string> = new Subject();
  displayedColumns: string[] = ['position', 'title', 'description', 'image', 'actions'];
  dataSource!: MatTableDataSource<PeriodicElement>;
  subscriptions: Subscription[] = [];
  customerRequests: any;
  allParams: any = {};
  errors: any;
  results: any;
  totalRecords = 0;
  pageNumbers = 0;
  pageLimits = 0;
  image: any;
  public S3_URL = environment.s3baseUrl;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  errorMessage: any;
  constructor(
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    private userService: UsersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.allParams = {
      pageNum: 1,
      pageLimit: 10
    };
    this.allGallery();
    this.subject.pipe(
      debounceTime(500),
      switchMap(value => this.userService.getAllGallery(this.allParams)),
    ).subscribe(
      result => {
        this.results = result;
        if (result) {
          this.dataSource = new MatTableDataSource(this.results.gallery);
          this.totalRecords = this.results.total;
          this.pageNumbers = this.results.pageNumber;
          this.pageLimits = this.results.pageLimit;
          this.spinner.hide();
        }
      },
      e => {
        this.spinner.hide();
        return this.errors = e.error;
      }
    );


  }

  allGallery(): void {
    this.spinner.show();
    this.userService.getAllGallery(this.allParams).subscribe(result => {
      if (result) {
        console.log(result);
        this.results = result;
        this.spinner.hide();
        this.customerRequests = this.results;
        console.log(this.customerRequests);
        this.totalRecords = this.results.total;
        this.pageNumbers = this.results.pageNumber;
        this.pageLimits = this.results.pageLimit;

        this.dataSource = new MatTableDataSource(this.customerRequests.gallery);
        this.dataSource.paginator = this.paginator;
      }
    },
      e => {
        this.spinner.hide();
        return this.errors = e.error;
      });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.allParams.name = filterValue;
    this.subject.next('');
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pageChanged(e: any): void {
    this.allParams.pageNum = e.pageIndex + 1;
    this.allParams.pageLimit = e.pageSize;
    this.allGallery();
  }

  addServices(id: string) {
    const dialogRef = this.dialog.open(EditModelComponent, {
      // width: '35vw',
      // maxWidth: '100vw',
      data: { userId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.allGallery();
      if (result.success && result.isEdit) {
        return this.notifyService.showSuccess('User update successfully!!', 'Register');
      }
      return result.success && !result.isEdit ? this.notifyService.showSuccess('Register successfully!!', 'Register')
        : this.notifyService.showWarning('Register Failed!!', 'Register');
    });
  }

  edit(id: string): void {
    this.addServices(id);
  }



  delete(id: string): void {
    this.subscriptions.push(this.userService.deleteGallery(id).subscribe((result: any) => {
      this.allGallery();
      return result.success ? this.notifyService.showSuccess('Delete successfully!!', 'Star Wedding')
        : this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
    },
      e => {
        console.log(e);
        this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
        return this.errors = e.error;
      }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
