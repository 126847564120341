<div class="customerContainer">
    <div class="container mt-3">
        <div class="row">
            <div class="col-lg-12">
                <div class="header">
                    <div>
                        <h3>Employees Details</h3>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="addUser('0')">Add User</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <!-- <td mat-cell *matCellDef="let element;let i=index;"> {{i+1}} </td> -->
                        <td mat-cell class="mat-cell" *matCellDef="let element; let i=index;" data-label="S.no">
                            {{ (i+1)+((this.pageNumbers-1)*
                            this.pageLimits)}}
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef> Phone </th>
                        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef> Role </th>
                        <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-raised-button color="primary" (click)="edit(element._id)">Edit</button>
                            <button mat-raised-button color="warn" (click)="delete(element._id)">Delete</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator *ngIf="totalRecords >= 10" [pageSize]="10" [pageSizeOptions]="[10, 20, 100]"
                    [length]="totalRecords" (page)="pageChanged($event)">
                </mat-paginator>
            </div>

        </div>
    </div>
</div>