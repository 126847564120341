<mat-dialog-content class="mat-typography">
    <div class="text-center">
        <h2>View Details</h2>
        <mat-divider></mat-divider>
    </div>
    <mat-list>
        <mat-list-item>Name: {{customerDetails.username}}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>Email: {{customerDetails.email}}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>Phone: {{customerDetails.phone}}</mat-list-item>
    </mat-list>
</mat-dialog-content>