import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UsersService } from 'src/app/services/users.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'app-add-services',
  templateUrl: './add-services.component.html',
  styleUrls: ['./add-services.component.scss']
})
export class AddServicesComponent implements OnInit {
  subscriptions: Subscription[] = [];
  addPriceForm: FormGroup;
  user: any;
  errors: any;
  image: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private userService: UsersService,
    private commonService: CommonService,
    private notifyService: NotificationService) { }

  ngOnInit() {
    this.addPriceForm = this.fb.group({
      title: new FormControl(''),
      description: new FormControl(''),
      image: new FormControl(''),
    });
  }

  uploadFile(event: any, filename: string): any {
    console.log(event);
    this.spinner.show();
    const droppedFiles = event.target.files;
    if (droppedFiles.length > 0) {
      const formData = new FormData();
      for (const item of droppedFiles) {
        formData.append('banner', item);
      }
      this.subscriptions.push(this.commonService.fileUpload(formData).subscribe(result => {
        if (result) {
          this.image = result.files.key;
          this.addPriceForm.controls.image.patchValue(result.files.key);
        }
        console.log(result);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      }));
    }
  }
  saveData() {
    if (this.addPriceForm.invalid) {
      return;
    }
    const obj = this.addPriceForm.value;
    if (this.image) {
      obj.image = this.image;
    }
    this.subscriptions.push(this.userService.addGallery(obj).subscribe(result => {
        if (result) {
          this.notifyService.showSuccess('Successfully Gallery Created', 'Gallery');
          this.router.navigate(['/backend/dashboard/service-gallery']);
        }
        console.log(result);
      },
      e => {
        return this.errors = e.error;
      }
    ));
  }
}

