import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {

  modalForm: FormGroup;
  botdata;
  profileDetails: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ProfileModalComponent>,
    private fb: FormBuilder
  ) {
    if (data) {
      this.botdata = data;
      console.log(this.botdata);
      console.log(this.botdata);
    }
  }

  ngOnInit(): void {
    this.modalForm = this.fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required]
    });
    const proUser = localStorage.getItem('currentUser');
    if (proUser) {
      this.profileDetails = JSON.parse(proUser);
      console.log(this.profileDetails);
    }
    this.modalForm.controls.username.patchValue(this.profileDetails.username);
    this.modalForm.controls.email.patchValue(this.profileDetails.email);
    this.modalForm.controls.phone.patchValue(this.profileDetails.phone);
    // this.sendLaterForm.get('dateControl').setValue(this.schedule);
  }
  onConfirmClick(): void {
    this.dialogRef.close(this.modalForm.value);
  }
}
