import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'view-edit-delete',
    templateUrl: './view-edit-delete.component.html',
    styleUrls: ['./view-edit-delete.component.scss']
})

// tslint:disable-next-line:component-class-suffix
export class ViewEditDeleteComponentDialog implements OnInit {
    id: any;
    message = 'Are you sure you want to delete?';
    viewForm: FormGroup;
    dataupdate: any;
    constructor(
        private dialogRef: MatDialogRef<ViewEditDeleteComponentDialog>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private fb: FormBuilder
    ) {
        if (data) {
            console.log(data);
            this.dataupdate = data;
        }
    }

    ngOnInit(): void {
        this.viewForm = this.fb.group({
            _id: [''],
            name: [''],
            class: [''],
            year: [''],
        });
        this.viewForm.controls._id.patchValue(this.dataupdate.student._id);
        this.viewForm.controls.name.patchValue(this.dataupdate.student.name);
        this.viewForm.controls.class.patchValue(this.dataupdate.student.class);
        this.viewForm.controls.year.patchValue(this.dataupdate.student.year);
    }
    onConfirmClick(): void {
        // if (this.viewForm.invalid) {
        //     return;
        // }
        this.dialogRef.close(this.viewForm.value);
    }
}
