<!-- <h2 mat-dialog-title>Work Together</h2> -->
<div id="content" #content >
  <h3 class="text-center">Customized Wedding Package*</h3>
  <div class="text-right">
    <h4 >(Month 00th Location pay by client | Hyderabad) 8hrs</h4>
  </div>
  <div class="pdfHeader">
    <div>
      <h4>{{worktogether.workTogether.name}}: </h4>
      <h6>Candid Photographer & Cinematographer</h6>
    </div>
    <div>
      <h4>(Aug 13th Morning 00am to 00pm | Hyderabad) {{worktogether.partofDays}}</h4>
    </div>
  </div>
  <div class="pdfHeader">
    <div>
      <h4>Engagement: </h4>
      <h6>Traditional Photographer & Videographer, Candid Photographer & Cinematographer</h6>
    </div>
    <div>
      <h4>(Aug 13th
        Morning 00am to 00pm | Hyderabad) {{worktogether.partofDays}}</h4>
    </div>
  </div>
  <div class="pdfHeader">
    <div>
      <h4>Bride Haldi + Formalities: </h4>
      <h6>Traditional Photographer & Traditional Videographer, Candid Photographer & Cinematographer
        Note: Traditional team only work for full day. Candid team for morning haldi event</h6>
    </div>
    <div>
      <h4>(Aug 25th
        Mor + Eve 00am to 00pm | Vikarabad) Full Day</h4>
    </div>
  </div>
  <div class="pdfHeader">
    <div>
      <h4>Groom Making: </h4>
      <h6>Traditional Photographer & Semi Candid Videographer, Candid Photographer
        Note: Traditional team only work for full day. Candid Photographer for morning haldi event.</h6>
    </div>
    <div>
      <h4>((Aug 25
        th
        Mor + Eve 00am to 00pm | Hyderabad) Half Day</h4>
    </div>
  </div>
  <div class="pdfHeader">
    <div>
      <h4>Wedding: </h4>
      <h6>Traditional Photographer & Videographer, Candid Photographer & Cinematographer, Drone
        LED 8X12 (1), Live Streaming (4hrs)
        Note: Traditional team only work for full day before and after wedding formalities till evening.</h6>
    </div>
    <div>
      <h4>(Aug 27
        th
        Morning 00am to 00pm | Hyderabad) Full Day</h4>
    </div>
  </div>
  <div>
    <h3>Deliverables</h3>
    <p class="p1">Albums & Videos Output:</p>
    <p>180 Total Sheets of Premium Quality RGB Velvet Sheets divided in to 6 albums – 3 each side
      Cinematic Wedding Teaser</p>
    <p>All Edited High Quality video outputs in Blue-ray DVD’</p>
  </div>
  <div>
    <h3>Complimentary</h3>
    <p>1 TB Hard Disk with complete RAW Footage</p>
    <p>8 Emboss Sheets in Album</p>
  </div>
  <div>
    <h3>Total Package Cost: 3,40,000/-rs</h3>
    <p class="p1">Payment Break-up :</p>
    <p>30% Advance to block the dates, 50% two days before the events, 10% Albums + 10% Video
    </p>
  </div>

  <div class="pdffooter">
    <p>Star Wedding</p>
    <p><a>www.facebook.com/momentmakersin</a></p>
    <p><a>www.momentmakers.in</a></p>
    <p>Contact: +91 9030123430 / +91 9595123430</p>
  </div>

  <div class="terms-condistions">
    <h3>Terms & Conditions</h3>
    <p><strong> Travel & Accommodation :</strong> Transport, Accommodation & food will need to be Arranged for our crew
      if the event is happening out of Hyderabad. </p>
    <p><strong>COPYRIGHTS : </strong>We retain copyrights of all the images & videos we produced and will use some of
      the
      images & videos on our professional website/social networking sites solely for promoting our work. </p>
    <p><strong>Event - Time Line:</strong> Each event will be covered up to 6hrs which is called as a single session.
      The
      Grace time of another 1hr can be added to the event. If the total event is extending more than 7Hrs
      extra session charges need to be paid which would be calculated as a second session for the
      particular event</p>
    <p><strong>Quality Part:</strong> Set Decorations & Costumes of Bride & Groom need to be properly taken care which
      will help us to give the best quality output. Proper time need to be provided to the team in order to
      work for creative shots and the support you give to the team results in getting the outstanding
      outputs. So we request you to give ample time to get the LIFE LONG MEMORIES..</p>
    <p><strong>Deliverables - Time Line :</strong> Total Events RAW/Original Footage need to be collected from us for
      selection of images to process the albums. Selection of pictures need to be given to us with in a
      month time after the marriage to start designing of albums part. (Note: Total backup will be given if
      there is no due in the second payment.) Any particular songs for teasers and regular video outputs
      or albums designing references should be given ASAP after the marriage which is prior to the Postproduction
      process in order to avoid the major changes. If not provided from the client we will take
      our decision to choose the best suitable songs that will be in synch with video. The same with the
      album designing also. Album Designing of selected photos (provided by client) takes approximately
      12 weeks. once the designs are approved, albums will be delivered within 5 working days. Video
      delivery will take minimum 12 weeks and this excludes modifications proposed by client.
      Maximum 2 modifications /changes are permitted for video / album designs. </p>
    <p><strong> Cancellation Policy:</strong> In the event of cancellation by the CLIENT, the retainer fee paid is
      refundable if
      the cancellation is made 60 days or more before the event .If the cancellation is made less than 60
      days before the event, the retainer fee is not refundable. In the unlikely event that Moment Makers
      is the party cancelling the appointment, the retainer fee is fully refundable. Note: Main family
      groups of a bride and groom & Important people should be organised by one of your family person
      by giving communication with the photographer in order not to miss any of family photos & imp.
      Persons.</p>
    <div class="termsfooter mt-3">
      <p><strong>Thanks & Regards:</strong></p>
      <p>Varun Kumar Ponnam</p>
      <p>(Founder/Manging Director)</p>
      <p>+91 9030123430 / + 91 9595123430</p>
    </div>
    <div class="pdffooter">
      <p>Star Wedding</p>
      <p><a>www.facebook.com/momentmakersin</a></p>
      <p><a>www.momentmakers.in</a></p>
      <p>Contact: +91 9030123430 / +91 9595123430</p>
    </div>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <h2 class="text-center"> Your Request Successfully Submitted!</h2>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="text-center mt-3 mb-2">
    Check Invoice Details 
    <button mat-raised-button color="warn" class="mt-2 mr-2" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="openPDF()">Download</button>
  </div>
</mat-dialog-actions>