import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-add-price',
  templateUrl: './add-price.component.html',
  styleUrls: ['./add-price.component.scss']
})
export class AddPriceComponent implements OnInit {
  addPriceForm: FormGroup;
  user: any;
  errors: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private notifyService: NotificationService) { }

  ngOnInit() {
    this.addPriceForm = this.fb.group({
      name: new FormControl(''),
      price: new FormControl('')
    });
  }
  savePrice() {
    const priceData = {
      name: this.addPriceForm.controls.name.value,
      price: this.addPriceForm.controls.price.value,
    };
    console.log(priceData);
    this.user = this.userService.getPriceData(priceData).subscribe(result => {
      // console.log(priceData);
      if (result) {
        this.notifyService.showSuccess('Added Price successfully!!', 'Price');
        this.router.navigate(['/backend/dashboard/price']);
      }
    },
      e => {
        return this.errors = e.error;
      });
  }
}
