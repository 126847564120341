<footer id="dk-footer" class="dk-footer">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div class="contact-us">
                    <div class="contact-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>

                    <div class="contact-info">
                        <h3>Location</h3>
                        <p>2nd floor, Vaishnavi’s Cynosure, Gachibowli, Hyderabad</p>
                    </div>
                </div>
                <div class="contact-us">
                    <div class="contact-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>

                    <div class="contact-info">
                        <h3>Email:</h3>
                        <p>info@fudosanproperties.com</p>
                    </div>
                </div>
                <div class="contact-us">
                    <div class="contact-icon">
                        <i class="fa fa-mobile" aria-hidden="true"></i>
                    </div>

                    <div class="contact-info">
                        <h3>Call:</h3>
                        <p>+91 9010795555</p>
                    </div>
                </div>
            </div>
            <!-- End Col -->
            <div class="col-md-6">
                <div class="contact-us contact-us-last">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.4812088795325!2d78.3669488!3d17.436667399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93ec20b2e87d%3A0xb4d6ba915f20fe4a!2sVaishnavi&#39;s%20Cynosure!5e0!3m2!1sen!2sin!4v1657884246568!5m2!1sen!2sin" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                 <div class="contact-us contact-us-last">
                    <a mat-mini-fab class="facebook" href="https://www.facebook.com/fdaofficialaccount" target="_blank">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>

                    <a mat-mini-fab class="instagram" target="_blank" href="https://www.instagram.com/fdaofficialaccount/">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>

                    <!-- <a mat-mini-fab class="youtube" href="https://www.youtube.com/channel/UCFzZ9V3yM81mDbRi9TnZr3A">
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                    </a> -->
                </div>
            </div>
            <!-- End Col -->
        </div>
    </div>
    <!-- End Contact Container -->
    <footer class="footer-mini">
        <div class="copyright">
            © Copyright <a href="" target="_blank"><strong><span>FUDOSAN</span></strong>.</a> All Rights Reserved
        </div>
    </footer>
</footer>