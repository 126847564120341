import { Component, OnInit, OnDestroy } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WorktogetherModalComponent } from '../worktogether-modal/worktogether-modal.component';
import { UsersService } from 'src/app/services/users.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';


interface Together {
  id: number;
  name: string;
  value: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  };

  togetherForm: FormGroup;
  isOpen: boolean;
  workToGether: any;
  isLogin = false;
  registerForm: FormGroup;
  leadsUser;
  info: boolean;
  subscriptions: Subscription[] = [];
  errors: any;
  errorMessage: any;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.registerForm = this.fb.group({
      phone: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      budget: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      property: new FormControl(''),
      bedrooms: new FormControl(''),
    });
  }

  sendEmail(e: Event) {
    e.preventDefault();
    
    emailjs.sendForm('service_1h2l6xc', 'template_71ys7cc', e.target as HTMLFormElement, 'MyHAPjR4I4YGC3js1')
      .then((result: EmailJSResponseStatus) => {
        console.log("Sent");
        this.registerForm.reset();
        alert("Submitted Successfully!");
        
      }, (error) => {
        console.log("Failed");
      });
      
  }

  submit(val: any) {
    if (this.registerForm.valid) {
      return;
    }
    const userData = {
        phone: this.registerForm.controls.phone.value,
        username: this.registerForm.controls.username.value,
        budget: this.registerForm.controls.budget.value,
        location: this.registerForm.controls.location.value,
        property: this.registerForm.controls.property.value,
        bedrooms: this.registerForm.controls.bedrooms.value,
      };
    this.leadsUser = this.userService.register(userData).subscribe(result => {
      },
        e => {
          console.log(e);
          return this.errors = e.error;
        });

  }

}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogContentExampleDialog {}
