import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private apiUrl = `${environment.apiUrl}`;
    private handleError: HandleError;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        private httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = this.httpErrorHandler.createHandleError('UsersService');
    }

    register(data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/user-signup`, data);
    }

    createUser(data: any) {
        return this.http.post(`${this.apiUrl}/auth/signup`, data)
            .pipe(
                catchError(this.handleError('createUsers', null))
            );
    }
    loginUser(data: any) {
        return this.http.post(`${this.apiUrl}/auth/signin`, data)
            .pipe(
                catchError(this.handleError('loginUsers', null))
            );
    }

    userProfile(data: any) {
        return this.http.post(`${this.apiUrl}/api/profile`, data);
    }

    addRequest(data: any) {
        return this.http.post(`${this.apiUrl}/api/add-request`, data);
    }
    getRequest(data: any) {
        return this.http.get(`${this.apiUrl}/api/get-requests`, { params: data });
    }

    deleteCustomer(id: string) {
        return this.http.get(`${this.apiUrl}/api/delete-customer/${id}`);
    }

    deletePrice(id: string) {
        return this.http.get(`${this.apiUrl}/api/delete-price/${id}`);
    }
    // Services Gallery APIS
    addGallery(data: any) {
        return this.http.post(`${this.apiUrl}/api/add-gallery`, data);
    }

    getAllGallery(data: any) {
        return this.http.get(`${this.apiUrl}/api/all-gallery`, { params: data });
    }
    deleteGallery(id: string) {
        return this.http.get(`${this.apiUrl}/api/gallery-delete/${id}`);
    }
    updateGallery(id: string, data: any) {
        return this.http.put(`${this.apiUrl}/api/update-gallery/${id}`, data);
    }
    getGallery(id: string) {
        return this.http.get(`${this.apiUrl}/api/gallery/${id}`);
    }
    // Employee services
    addEmployee(data: any) {
        return this.http.post(`${this.apiUrl}/api/add-employee`, data);
    }
    getEmployees(data: any) {
        return this.http.get(`${this.apiUrl}/api/employees`, { params: data });
    }

    deleteEmployee(id: string) {
        return this.http.get(`${this.apiUrl}/api/delete-employee/${id}`);
    }

    getEmployee(id: string) {
        return this.http.get(`${this.apiUrl}/api/user/${id}`);
    }

    updateEmployee(id: string, data: any) {
        return this.http.put(`${this.apiUrl}/api/update-employee/${id}`, data);
    }

    getWorkDetails() {
        return this.http.get(`${this.apiUrl}/api/worktogether`)
            .pipe(
                catchError(this.handleError('getAllWorktogether', null))
            );
    }

    fileUpload(fileData: any): Observable<any> {
        console.log(fileData);
        return this.http.post(`${this.apiUrl}/api/file-upload`, fileData);
    }

    imgDelete(key: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/api/file-delete`, key);
    }
    getPriceDetails(): Observable<any> {
        return this.http.get(`${this.apiUrl}/api/price-list`);
    }

    getPriceData(data: any): Observable<any> {
        console.log(data);
        return this.http.post(`${this.apiUrl}/api/add-price`, data);
    }
    getStudents() {
        return this.http.get(`${this.apiUrl}/students`)
            .pipe(
                catchError(this.handleError('Allstudents', null))
            );
    }
    updateStudentId(data: any) {
        console.log(data);
        return this.http.put(`${this.apiUrl}/update-student/` + data._id, data).pipe(
            catchError(this.handleError('Students', null))
        );
    }
    deleteStudentId(data: any) {
        return this.http.delete(`${this.apiUrl}/remove-student/` + data.id).pipe(
            catchError(this.handleError('Students', null))
        );
    }
}
