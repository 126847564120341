import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/services/users.service';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDialogComponent } from 'src/app/cms/modal-dialog/modal-dialog.component';
import { ViewComponent } from './view/view.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
  public subject: Subject<string> = new Subject();
  displayedColumns: string[] = ['position', 'username', 'phone', 'email', 'category'];
  dataSource!: MatTableDataSource<PeriodicElement>;
  subscriptions: Subscription[] = [];
  customerRequests: any;
  allParams: any = {};
  errors: any;
  results: any;
  totalRecords = 0;
  pageNumbers = 0;
  pageLimits = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private spinner: NgxSpinnerService,
    private notifyService: NotificationService,
    private userService: UsersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.allParams = {
      pageNum: 1,
      pageLimit: 10
    };
    this.allProjects();
    this.subject.pipe(
      debounceTime(500),
      switchMap(value => this.userService.getRequest(this.allParams)),
    ).subscribe(
      result => {
        this.results = result;
        if (result) {
          this.dataSource = new MatTableDataSource(this.results.items);
          this.totalRecords = this.results.total;
          this.pageNumbers = this.results.pageNumber;
          this.pageLimits = this.results.pageLimit;
          this.spinner.hide();
        }
      },
      e => {
        this.spinner.hide();
        return this.errors = e.error;
      }
    );


  }

  allProjects(): void {
    this.spinner.show();
    this.userService.getRequest(this.allParams).subscribe(result => {
      if (result) {
        this.results = result;
        this.spinner.hide();
        this.customerRequests = this.results;
        this.totalRecords = this.results.total;
        this.pageNumbers = this.results.pageNumber;
        this.pageLimits = this.results.pageLimit;
        this.dataSource = new MatTableDataSource(this.customerRequests.items);
        this.dataSource.paginator = this.paginator;
      }
    },
      e => {
        this.spinner.hide();
        return this.errors = e.error;
      });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.allParams.name = filterValue;
    this.subject.next('');
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pageChanged(e: any): void {
    this.allParams.pageNum = e.pageIndex + 1;
    this.allParams.pageLimit = e.pageSize;
    this.allProjects();
  }

  view(id: string): void {
    const dialogRef = this.dialog.open(ViewComponent, {
      data: id
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    }));
  }

  download(value): void {
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      data: value
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.subscriptions.push(this.userService.addRequest(payload).subscribe({
        //   next: data => {
        //     this.notifyService.showSuccess('Request Submitted successfully!!', 'workToGether');
        //     return this.router.navigateByUrl('/backend/dashboard');
        //   },
        //   error: error => {
        //     this.errorMessage = error.message;
        //   }
        // })
        // );
      }
    }));
  }

  delete(id: string): void {
    this.subscriptions.push(this.userService.deleteCustomer(id).subscribe((result: any) => {
      this.allProjects();
      return result.success ? this.notifyService.showSuccess('Delete successfully!!', 'Star Wedding')
        : this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
    },
      e => {
        console.log(e);
        this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
        return this.errors = e.error;
      }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
