import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { UsersService } from 'src/app/services/users.service';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
    selector: 'app-edit',
    templateUrl: './edit-modal.component.html',
    styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit {
    id: any;
    editForm: FormGroup;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        // private userService: UsersService,
        private fb: FormBuilder) {
        if (data) {
            this.id = data;
        }
    }

    ngOnInit() {
        this.editForm = this.fb.group({
            name: ['', Validators.required],
            class: ['', Validators.required],
            year: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

}
