import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  info: boolean;
  constructor(
    private message: MessageService
  ) { }

  ngOnInit() {
    this.message.buyerDropdown(false);
  }

}
