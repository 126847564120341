<h2 mat-dialog-title>User Details</h2>
<form [formGroup]="modalForm">
    <mat-dialog-content class="mat-typography profile-component">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Full Name</mat-label>
                        <input matInput placeholder="Full Name" formControlName="username">
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput placeholder="Email" formControlName="phone">
                    </mat-form-field>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <!-- <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">Save </button>
        <button mat-raised-button color="warn" [mat-dialog-close] tabindex="-1">Cancel</button> -->
    </mat-dialog-actions>
</form>