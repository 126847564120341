import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UsersService } from 'src/app/services/users.service';

interface DialogData {
  galleryDetails: any;
}

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.scss']
})
export class EditModelComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  addPriceForm: FormGroup;
  errors: any;
  isEdit = false;
  userId: string;
  image: any;
  constructor(
    private notifyService: NotificationService,
    private userService: UsersService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<EditModelComponent>,
    private fb: FormBuilder
  ) {
    if (data) {
      this.userId = data.userId;
    }
  }

  ngOnInit(): void {
    if (this.userId !== '0') {
      this.subscriptions.push(this.userService.getGallery(this.userId).subscribe((result: any) => {
        console.log(result.gallery);
        this.isEdit = true;
        // tslint:disable-next-line:max-line-length
        return result.success ? this.addPriceForm.patchValue(result.gallery) : this.notifyService.showWarning('User not found', 'Star Wedding');
      },
        e => {
          console.log(e);
          this.notifyService.showWarning('User not found', 'Star Wedding');
          return this.errors = e.error;
        }));
    }
    this.addPriceForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: ['', Validators.required]
    });
  }

  uploadFile(event: any, filename: string): any {
    console.log(event);
    this.spinner.show();
    const droppedFiles = event.target.files;
    if (droppedFiles.length > 0) {
      const formData = new FormData();
      for (const item of droppedFiles) {
        formData.append('banner', item);
      }
      this.subscriptions.push(this.commonService.fileUpload(formData).subscribe(result => {
        if (result) {
          this.image = result.files.key;
          this.addPriceForm.controls.image.patchValue(result.files.key);
        }
        console.log(result);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      }));
    }
  }
  saveData(): void {
    const obj = this.addPriceForm.value;
    if (this.image) {
      obj.image = this.image;
    }
    if (this.isEdit) {
      this.subscriptions.push(this.userService.updateGallery(this.userId, this.addPriceForm.value).subscribe((result: any) => {
        return result.success ? this.dialogRef.close({ success: true, isEdit: this.isEdit })
          : this.dialogRef.close({ success: false, isEdit: this.isEdit });
      },
        e => {
          console.log(e);
          this.dialogRef.close({ success: false, isEdit: this.isEdit });
          return this.errors = e.error;
        }));
    } else {
      this.subscriptions.push(this.userService.addGallery(this.addPriceForm.value).subscribe((result: any) => {
        return result.success ? this.dialogRef.close({ success: true, isEdit: this.isEdit })
          : this.dialogRef.close({ success: false, isEdit: this.isEdit });
      },
        e => {
          console.log(e);
          this.dialogRef.close({ success: false, isEdit: this.isEdit });
          return this.errors = e.error;
        }));
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
