<div class="customerContainer">
    <div class="container mt-3">
        <div class="row">
            <div class="col-lg-12">
                <mat-card class="">
                    <h3 class="text-center">Add Services</h3>
                    <form [formGroup]="addPriceForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Title</mat-label>
                                    <input matInput placeholder="Enter Price" minlength="2" maxlength="30"
                                        formControlName="title">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12">
                                <mat-form-field appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <input matInput placeholder="Enter Price" minlength="2" maxlength="130"
                                        formControlName="description">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-10">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Supported Formats:- PNG,JPEG,JPG</mat-label>
                                            <input matInput placeholder="Supported Formats:- PNG,JPEG,JPG" readonly
                                                formControlName="image">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-element">
                                            <button mat-raised-button color="primary" class="banner-upload upload-btn"
                                                type="button">
                                                <mat-icon>cloud_upload</mat-icon>
                                                Upload
                                                <input type="file" class="browse-file" (change)="uploadFile($event,'banner')">
                                                <span class="uploadFile"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button mat-raised-button color="primary" type="submit" (click)="saveData()">Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>

        </div>
    </div>
</div>