import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemoMaterialModule } from '../material.module';
import { ViewEditDeleteComponentDialog } from './dashboard/view-edit-delete/view-edit-delete.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AddServicesComponent } from './service-gallery/add-services/add-services.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import { PriceListComponent } from './price-list/price-list.component';
import { AddPriceComponent } from './add-price/add-price.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EmployeeModalComponent } from './employee-details/employee-modal/employee-modal.component';
import { ViewComponent } from './customer-details/view/view.component';
import { ServiceGalleryComponent } from './service-gallery/service-gallery.component';
import { EditModelComponent } from './service-gallery/edit-model/edit-model.component';


@NgModule({
  declarations: [
    DashboardComponent,
    ViewEditDeleteComponentDialog,
    CustomerDetailsComponent,
    AddServicesComponent,
    EmployeeDetailsComponent,
    ProfileModalComponent,
    EditModalComponent,
    PriceListComponent,
    AddPriceComponent,
    EmployeeModalComponent,
    ViewComponent,
    ServiceGalleryComponent,
    AddServicesComponent,
    EditModelComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
  entryComponents: [
    ViewEditDeleteComponentDialog,
    ProfileModalComponent,
    EditModalComponent,
    AddServicesComponent,
    EmployeeModalComponent,
  ]
})
export class DashboardModule { }
