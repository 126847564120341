import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfileModalComponent } from 'src/app/dashboard/profile-modal/profile-modal.component';

@Component({
  selector: 'app-backend-header',
  templateUrl: './backend-header.component.html',
  styleUrls: ['./backend-header.component.scss']
})
export class BackendHeaderComponent implements OnInit {

  events: string[] = [];
  opened = true;
  constructor(
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
  }
  profile() {
    const dialogRef = this.dialog.open(ProfileModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  logout(): void {
    const currentuser = localStorage.removeItem('currentUser');
    console.log(currentuser);
    this.router.navigate(['/auth/login']);
  }
}
