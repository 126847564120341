import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  User = {
    name: String,
    lastName: String,
  };
  private apiUrl = environment.apiUrl;
  currentUser: any;
  redirectUrl: string | undefined;
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }
  login(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/login`, data);
  }
  signup(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/register`, data);
  }

  public signOut(): void {
    localStorage.removeItem('accessToken');
  }
  public getCurrentUser(): any {
    try {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      return this.currentUser;
    } catch (e) {
      return null;
    }

  }
  public getDecodeToken(token: string): void {
    return jwt_decode(token);
  }

  public isLoggedIn(): any {
    const currentUser = this.getCurrentUser();
    if (currentUser && currentUser.accessToken) {
      const token: any = this.getDecodeToken(currentUser.accessToken);
      const currentTime = Math.round((new Date()).getTime() / 1000);
      if (token.exp > currentTime) {
        return true;
      } else {
        this.logout();
      }
    }
    return false;
  }
  public logout(): any {
    localStorage.removeItem('currentUser');
    // this.router.navigateByUrl(`/${routes.LOGIN}`);
  }
}
