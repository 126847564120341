<div class="customerContainer">
    <div class="container mt-3">
        <div class="row">
            <div class="col-lg-12">
                <div class="price-heading">
                    <mat-card>
                        <h3 class="text-center">Add Price</h3>
                        <form [formGroup]="addPriceForm">
                            <div class="row">
                                <div class="col-lg-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Choose Option</mat-label>
                                        <mat-select formControlName="name">
                                            <mat-option value="sheet">Sheet</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput placeholder="Enter Price" minlength="2" maxlength="5"
                                            formControlName="price" appNumbersOnly>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <button mat-raised-button color="primary" type="submit" (click)="savePrice()">Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card>
                </div>
            </div>
        </div>

    </div>
</div>