<h2 mat-dialog-title>Add User</h2>
<form [formGroup]="modalForm">
    <mat-dialog-content class="mat-typography">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Full Name</mat-label>
                        <input matInput placeholder="Full Name" formControlName="username">
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Select Gender</mat-label>
                        <mat-select formControlName="gender">
                            <mat-option value="male">Male</mat-option>
                            <mat-option value="female">Female</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Experience</mat-label>
                        <mat-select formControlName="experience">
                            <mat-option value="1">1yr</mat-option>
                            <mat-option value="2">2yr</mat-option>
                            <mat-option value="3">3yr</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Role</mat-label>
                        <mat-select formControlName="role">
                            <mat-option value="101">Manager</mat-option>
                            <mat-option value="102">Assistant</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput placeholder="Email" formControlName="phone">
                    </mat-form-field>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">Save </button>
        <button mat-raised-button color="warn" [mat-dialog-close] tabindex="-1">Cancel</button>
    </mat-dialog-actions>
</form>