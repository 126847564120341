<header class="micro-nav fixed-top">
    <nav class="navbar navbar-expand-lg navbar-light mini-navbar">
        <div class="container">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navcollapsemini">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- <p>Call: +91 90107 95555</p> -->
            <div class="collapse navbar-collapse" id="navcollapsemini">
                <ul class="navbar-nav nav-fill mini-menu">
                    <li class="nav-item">
                        <a class="nav-link">
                            +91 90107 95555
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link">
                            Mekiors Pride, CS Co-working 5th Floor, Izzathnagar, Khanammet, Hyderabad</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link"> info@propertyhunters.in</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light micro-navbar shadow-lg">
        <div class="container">
            <a class="navbar-brand" href="#">
                <img src="../../../assets/phlion_logo.png" />

            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navcollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- <p>Call: +91 90107 95555</p> -->
            <div class="collapse navbar-collapse main-header" id="navcollapse">
                <ul class="navbar-nav nav-fill ml-auto menu">
                    <li class="nav-item">
                        <a class="nav-link active" href="#home">
                            Home
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#pages">Projects </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="#gallery">Gallery</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" href="#aboutus">About US </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="#contact">Contact</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="#contact"> Contact </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</header>
<div class="container-fluid slider section" id="home">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol>

        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../../assets/Sldining-images/Lansam(1366x900).jpg" alt="Los Angeles">
                <div class="carousel-caption">
                    <h2>Lansum El Dorado</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Narsingi, Hyderabad, 500075</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,35,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>

            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Sumadhura(1366x900).jpg" alt="Chicago">
                <div class="carousel-caption">
                    <h2>Sumadhura Gardens By the Book</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Sathamrai Village, Shamshabad, Hyderabad,
                            501218</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.80,00,000/--</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>

            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Kenworth(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Kenworth By Provident</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Pillar No.293, PVNR Expressway, Shivrampalli,
                            Hyderabad, 500052</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.63,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>

            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Spectra(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>ASBL Spectra</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Financial District, Hyderabad, 500032</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,50,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>

            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Brigade(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Brigade Citadel</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Moti Nagar, Hyderabad, 500018</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,10,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>

            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Ambience(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Sukhii Group Ambience Courtyard</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Manikonda, Hyderabad, 500089</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,35,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Ubuntu(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Sukhii Group Ubuntu</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Puppalguda, Hyderabad, 500075</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,35,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Candeur(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Candeur Twins</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Miyapur, Hyderabad, 500049</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,30,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Raghuram(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Raghuram A2A homeland</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Balanagar, Hyderabad, 500037</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.1,15,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/Sldining-images/Urbanrise(1366x900).jpg" alt="New york">
                <div class="carousel-caption">
                    <h2>Urbanrise On cloud 33</h2>
                    <p class="location">
                        <mat-icon role="img" color="primary"
                            class="mat-icon notranslate mat-primary material-icons mat-icon-lg" aria-hidden="true">
                            location_on</mat-icon><span class="px-1">Bachupally, Hyderabad, 500090</span>
                    </p>
                    <button mat-raised-button="" color="primary"
                        class="mat-focus-indicator price mat-raised-button mat-button-base mat-primary"><span
                            class="mat-button-wrapper"><span class="price ng-star-inserted"><span
                                    class="ng-star-inserted">Rs.78,00,000/-</span>
                            </span>
                        </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                            class="mat-button-focus-overlay"></span></button>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#myCarousel" data-slide="next">
            <span class="carousel-control-next-icon"></span>
        </a>
    </div>
</div>

<!--feedback-form-->
<div id="feedback-form" class="feedback-form">
    <a class="feedback-form-btn btn btn-primary btn-lg" data-toggle="collapse" data-target="#demo">Enquiry</a>
    <div id="demo" class="feedback_form_area collapse">
        <div class="feedback_form_area_inner">
            <div class="form-logo">
                <!-- <img src="../../../assets/phlion_logo.png" width="200px" /> -->
                <img src="../../../assets/phlion_logo.png" width="200px" />
            </div>
            <form [formGroup]="registerForm" class="form-side" (submit)="sendEmail($event)">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Name" formControlName="username" name="name"/>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <span matPrefix>+91 &nbsp;</span>
                    <input type="tel" matInput placeholder="555-555-1234" maxlength="10" formControlName="phone" name="phone">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Budget" formControlName="budget" name="budget"/>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Location" formControlName="location" name="location"/>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Select a Property</mat-label>
                    <mat-select formControlName="property" #matSelectProperty >
                        <mat-option value="Lansum El Dorado">Lansum El Dorado</mat-option>
                        <mat-option value="Sumadhura Gardens By the Book">Sumadhura Gardens By the Book</mat-option>
                        <mat-option value="Kenworth By Provident"> Kenworth By Provident</mat-option>
                        <mat-option value="ASBL Spectra">ASBL Spectra</mat-option>
                        <mat-option value="Brigade Citadel">Brigade Citadel</mat-option>
                        <mat-option value="Sukhii Group Ambience Courtyard">Sukhii Group Ambience Courtyard</mat-option>
                        <mat-option value="Sukhii Group Ubuntu">Sukhii Group Ubuntu</mat-option>
                        <mat-option value="Candeur Twins">Candeur Twins</mat-option>
                        <mat-option value="Raghuram A2A homeland">Raghuram A2A homeland</mat-option>
                        <mat-option value="Urbanrise On cloud 33">Urbanrise On cloud 33</mat-option>
                    </mat-select>
                </mat-form-field>
                <input type="hidden" name="property" [value]="matSelectProperty.value" />
                <mat-form-field class="example-full-width">
                    <mat-label>No of Bedrooms</mat-label>
                    <mat-select formControlName="bedrooms" #matSelectBedrooms>
                        <mat-option value="2 BHK">2 BHK</mat-option>
                        <mat-option value="2.5 BHK">2.5 BHK</mat-option>
                        <mat-option value="3 BHK">3 BHK</mat-option>
                        <mat-option value="4 BHK">4 BHK</mat-option>
                    </mat-select>
                </mat-form-field>
                <input type="hidden" name="bedrooms" [value]="matSelectBedrooms.value" />
                <div class="text-center">
                    <button type="submit" name="enq_formBtn"
                        class="btn btn-primary micro-form-btn mt-2 effetMoveGradient" (click)="submit('pre')"
                        [disabled]="registerForm.invalid">
                        Submit
                    </button>
                </div>

            </form>

        </div>
    </div>

</div>
<!--feedback-form-->
<div class="section featured-properties mt-3 pt-5 pb-5" id="pages">
    <div class="px-3">
        <div class="theme-container">
            <h2 class="section-title">FEATURED PROPERTIES</h2>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Lansam_3BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Lansum El Dorado</h2>
                            <p class="mt-3">Narsingi, Hyderabad, 500075</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.95,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1540 sq.ft.</span></p>
                                <p><span>BHK</span><span>3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right">
                                <a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.lansumel-dorado.co.in
                                    "><span class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span>
                                </a>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Sumadhura_2BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Sumadhura Gardens By the Brook</h2>
                            <p class="mt-3">Sathamrai Village, Shamshabad, Hyderabad, 501218</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.70,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1285 sq.ft.</span></p>
                                <p><span>BHK</span><span>2, 2.5, 3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right">
                                <a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.sumadhuragardenbythebrook.co.in"><span
                                        class="mat-button-wrapper">Details
                                    </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span>
                                </a>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Kenworth_2BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Kenworth By Provident</h2>
                            <p class="mt-3">Pillar No.293, PVNR Expressway, Shivrampalli, Hyderabad, 500052</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.59,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>900 sq.ft.</span></p>
                                <p><span>BHK</span><span>2, 2.5, 3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.kenworthbyprovident.co.in/"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Spectra_3BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">ASBL Spectra</h2>
                            <p class="mt-3">Financial District, Hyderabad, 500032</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.1,35,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1980 sq.ft.</span></p>
                                <p><span>BHK</span><span>3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.asblspectrahyd.co.in"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Brigade_2BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Brigade Citadel</h2>
                            <p class="mt-3">Moti Nagar, Hyderabad, 500018</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.88,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1174 sq.ft.</span></p>
                                <p><span>BHK</span><span>2, 3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.brigadecitadelhyderabad.co.in"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Ambience_2BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Sukhii Group Ambience Courtyard</h2>
                            <p class="mt-3">Manikonda, Hyderabad, 500089</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.90,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1065 sq.ft.</span></p>
                                <p><span>BHK</span><span>2, 2.5, 3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.ambiencecourtyardhyd.co.in"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Ubuntu_2BHK.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Sukhii Group Ubuntu</h2>
                            <p class="mt-3">Puppalguda, Hyderabad, 500075</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.90,00,000/- Onwards</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1420 sq.ft.</span></p>
                                <p><span>BHK</span><span>2, 3, 4</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.sumadhuragardenbythebrook.co.in"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Candeur.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Candeur Twins</h2>
                            <p class="mt-3">Miyapur, Hyderabad, 500049</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.1,30,00,000/-</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1590 sq.ft.</span></p>
                                <p><span>BHK</span><span>3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.sumadhuragardenbythebrook.co.in"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Raghuram.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Raghuram A2A homeland</h2>
                            <p class="mt-3"> Balanagar, Hyderabad, 500037</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.1,15,00,000/-</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1700 sq.ft.</span></p>
                                <p><span>BHK</span><span>3</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.brigadecitadelhyderabad.co.in"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>

                <div class="col-xs-12 col-sm-6 col-lg-3">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/property-images/Urbanrise.jpg">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">Urbanrise On cloud 33</h2>
                            <p class="mt-3">Bachupally, Hyderabad, 500090</p>
                            <div>
                                <h3 class="primary-color price"><span>Rs.78,00,000/-</span></h3>
                            </div>
                            <div class="features mt-3">
                                <p><span>Property size</span><span>1110 sq.ft.</span></p>
                                <p><span>BHK</span><span>2, 3, 4</span></p>
                            </div>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href="https://www.kenworthbyprovident.co.in/"><span
                                        class="mat-button-wrapper">Details</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="section" id="aboutus">
    <div class="section default">
        <div class="container">
            <div class="theme-container">
                <h1 class="section-title text-center">Our Mission</h1>
                <mat-card class="mat-card mat-focus-indicator p-0">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 pl-5">
                            <div class="row pt-2">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <mat-icon role="img" color="primary"
                                        class="mat-icon notranslate mat-icon-xlg mat-primary material-icons"
                                        aria-hidden="true" data-mat-icon-type="font">monetization_on</mat-icon>
                                    <h2 class="capitalize fw-600 my-2">Save Money</h2>
                                    <p>Our experts not just show you the property. Will also help you, Where to Invest
                                    </p>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <mat-icon role="img" color="primary"
                                        class="mat-icon notranslate mat-icon-xlg mat-primary material-icons"
                                        aria-hidden="true" data-mat-icon-type="font">thumb_up</mat-icon>
                                    <h2 class="capitalize fw-600 my-2">Better Ideas</h2>
                                    <p>Our team has tied-up with more than 100 great builder, Giving you best
                                        options based on your requirement</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <mat-icon role="img" color="primary"
                                        class="mat-icon notranslate mat-icon-xlg mat-primary material-icons"
                                        aria-hidden="true" data-mat-icon-type="font">group</mat-icon>
                                    <h2 class="capitalize fw-600 my-2">Collaboration</h2>
                                    <p>Our team manage mandates, We have Collaborated with Provident Landlord
                                        share and many more in executions mandates</p>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <mat-icon role="img" color="primary"
                                        class="mat-icon notranslate mat-icon-xlg mat-primary material-icons"
                                        aria-hidden="true" data-mat-icon-type="font">search</mat-icon>
                                    <h2 class="capitalize fw-600 my-2">Easy To Find
                                    </h2>
                                    <p>Our team gives you hand full of Options along with best option based on your
                                        requirement, helping you to choose easily</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <img src="assets/mission23.jpg" alt="mission" class="mw-100 d-block mission-image">
                        </div>
                    </div>

                </mat-card>
            </div>
        </div>
    </div>
</section>
<div class="section mt-3 pt-5 pb-5">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">Our Services</h1>
            <div class="container">
                <div class="row services-wrapper">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 p-2">
                        <mat-card class="mat-card mat-focus-indicator h-100 w-100 text-center">
                            <mat-icon role="img" color="primary"
                                class="mat-icon notranslate mat-icon-xlg mat-primary material-icons" aria-hidden="true"
                                data-mat-icon-type="font">location_on</mat-icon>
                            <h2 class="capitalize fw-600 my-3">Find places anywhere in the world</h2>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                vestibulum egestas nunc sed ultricies.</p> -->
                        </mat-card>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 p-2">
                        <mat-card class="mat-card mat-focus-indicator h-100 w-100 text-center">
                            <mat-icon role="img" color="primary"
                                class="mat-icon notranslate mat-icon-xlg mat-primary material-icons" aria-hidden="true"
                                data-mat-icon-type="font">supervisor_account</mat-icon>
                            <h2 class="capitalize fw-600 my-3">We have agents with experience</h2>
                            <!-- <p _ngcontent-serverapp-c239="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                vestibulum egestas nunc sed ultricies.</p> -->
                        </mat-card>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 p-2">
                        <mat-card class="mat-card mat-focus-indicator h-100 w-100 text-center">
                            <mat-icon role="img" color="primary"
                                class="mat-icon notranslate mat-icon-xlg mat-primary material-icons" aria-hidden="true"
                                data-mat-icon-type="font">home</mat-icon>
                            <h2 class="capitalize fw-600 my-3">Buy or rent beautiful properties</h2>
                            <!-- <p _ngcontent-serverapp-c239="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                vestibulum egestas nunc sed ultricies.</p> -->
                        </mat-card>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 p-2">
                        <mat-card class="mat-card mat-focus-indicator h-100 w-100 text-center">
                            <mat-icon role="img" color="primary"
                                class="mat-icon notranslate mat-icon-xlg mat-primary material-icons" aria-hidden="true"
                                data-mat-icon-type="font">format_list_bulleted</mat-icon>
                            <h2 class="capitalize fw-600 my-3">With agent account you can list properties</h2>
                            <!-- <p _ngcontent-serverapp-c239="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                vestibulum egestas nunc sed ultricies.</p> -->
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="section agents mt-3 pt-5 pb-5">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title" style="color: white;">Sales Executives</h1>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-4">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/blank-profile-picture-973460_640.webp">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">N Chandan</h2>
                            <p class="mt-3">Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat
                                enim
                                eleifend nulla, at sagittis enim urna et lacus.</p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">business</mat-icon>
                               <span class="mx-2">Property Hunters</span>
                            </p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">email</mat-icon>
                                <span class="mx-2">chandan@propertyhunters.in</span>
                            </p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">call</mat-icon>
                               <span class="mx-2">+91 9010795555</span>
                            </p>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href=""><span class="mat-button-wrapper">View
                                        Profile</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-4">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/blank-profile-picture-973460_640.webp">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">U Raju</h2>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">business</mat-icon>
                                <span class="mx-2">Property Hunters</span>
                            </p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">email</mat-icon>
                                <span class="mx-2">raju@propertyhunters.in</span>
                            </p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">call</mat-icon>
                                <span class="mx-2">+91 6360117455</span>
                            </p>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href=""><span class="mat-button-wrapper">View
                                        Profile</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-4">
                    <mat-card>
                        <img mat-card-image="" alt="" class="mat-card-image swiper-lazy d-block swiper-lazy-loaded"
                            src="../../../assets/blank-profile-picture-973460_640.webp">
                        <mat-card-content class="mat-card-content">
                            <h2 class="fw-600">A Ajay</h2>

                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">business</mat-icon>
                                <span class="mx-2">Property Hunters</span>
                            </p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">email</mat-icon>
                                <span class="mx-2">ajay@propertyhunters.in</span>
                            </p>
                            <p>
                                <mat-icon role="img" color="primary" mattooltip="Organization"
                                    mattooltipposition="above"
                                    class="mat-icon notranslate mat-tooltip-trigger mat-primary material-icons"
                                    aria-hidden="true">call</mat-icon>
                                <span class="mx-2">+91 8977227992</span>
                            </p>
                        </mat-card-content>
                        <mat-divider role="separator" class="mat-divider mat-divider-horizontal"
                            aria-orientation="horizontal"></mat-divider>
                        <mat-card-actions>
                            <div class="text-right"><a mat-button="" color="primary"
                                    class="mat-focus-indicator uppercase mat-button mat-button-base mat-primary"
                                    aria-disabled="false" href=""><span class="mat-button-wrapper">View
                                        Profile</span><span matripple=""
                                        class="mat-ripple mat-button-ripple"></span><span
                                        class="mat-button-focus-overlay"></span></a></div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="section mt-3 pt-5 pb-5">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">OUR CLIENTS</h1>
        </div>
        <div class="container">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/ASBL.png" alt="ASBL" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Brigade.png" alt="Brigade" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Kalpataru.PNG" alt="Kalpataru" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Lansum.png" alt="Lansum" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Prestige.png" alt="Prestige" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Provident.png" alt="Provident" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Sumadhura.png" alt="Sumadhura" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Urbanrise.png" alt="Urbanrise" />
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="client-item">
                        <img src="../../../assets/client-logo/Vasavi.png" alt="Vasavi" />
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div> -->
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center">
                <div class="modal-logo-container">
                    <!-- <img src="../../../assets/logo/Property Hunters.png" width="200px" class="logo" /> -->
                    <img src="../../../assets/phlion_logo.png" width="200px" />
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="">
                    <form [formGroup]="registerForm" class="form-side" (submit)="sendEmail($event)">
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Name" formControlName="username" name="name"/>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <span matPrefix>+91 &nbsp;</span>
                            <input type="tel" matInput placeholder="555-555-1234" maxlength="10" formControlName="phone" name="phone">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Budget" formControlName="budget" name="budget"/>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Location" formControlName="location" name="location"/>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Select a Property</mat-label>
                            <mat-select formControlName="property" #matSelectProperty >
                                <mat-option value="Lansum El Dorado">Lansum El Dorado</mat-option>
                                <mat-option value="Sumadhura Gardens By the Book">Sumadhura Gardens By the Book</mat-option>
                                <mat-option value="Kenworth By Provident"> Kenworth By Provident</mat-option>
                                <mat-option value="ASBL Spectra">ASBL Spectra</mat-option>
                                <mat-option value="Brigade Citadel">Brigade Citadel</mat-option>
                                <mat-option value="Sukhii Group Ambience Courtyard">Sukhii Group Ambience Courtyard</mat-option>
                                <mat-option value="Sukhii Group Ubuntu">Sukhii Group Ubuntu</mat-option>
                                <mat-option value="Candeur Twins">Candeur Twins</mat-option>
                                <mat-option value="Raghuram A2A homeland">Raghuram A2A homeland</mat-option>
                                <mat-option value="Urbanrise On cloud 33">Urbanrise On cloud 33</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <input type="hidden" name="property" [value]="matSelectProperty.value" />
                        <mat-form-field class="example-full-width">
                            <mat-label>No of Bedrooms</mat-label>
                            <mat-select formControlName="bedrooms" #matSelectBedrooms>
                                <mat-option value="2 BHK">2 BHK</mat-option>
                                <mat-option value="2.5 BHK">2.5 BHK</mat-option>
                                <mat-option value="3 BHK">3 BHK</mat-option>
                                <mat-option value="4 BHK">4 BHK</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <input type="hidden" name="bedrooms" [value]="matSelectBedrooms.value" />
                        <div class="text-center">
                            <button type="submit" name="enq_formBtn"
                                class="btn btn-primary micro-form-btn mt-2 effetMoveGradient" (click)="submit('pre')"
                                [disabled]="registerForm.invalid">
                                Submit
                            </button>
                        </div>
        
                    </form>
                </div>

                <a href="tel:+919010795555" class="modal-call-btn"><i class="mi mi-call"></i> +91 9010795555</a>
            </div>
            <!-- <div id="card" class="animated fadeIn">
                <div id="upper-side">
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve">
                        <path d="M131.583,92.152l-0.026-0.041c-0.713-1.118-2.197-1.447-3.316-0.734l-31.782,20.257l-4.74-12.65
            c-0.483-1.29-1.882-1.958-3.124-1.493l-0.045,0.017c-1.242,0.465-1.857,1.888-1.374,3.178l5.763,15.382
            c0.131,0.351,0.334,0.65,0.579,0.898c0.028,0.029,0.06,0.052,0.089,0.08c0.08,0.073,0.159,0.147,0.246,0.209
            c0.071,0.051,0.147,0.091,0.222,0.133c0.058,0.033,0.115,0.069,0.175,0.097c0.081,0.037,0.165,0.063,0.249,0.091
            c0.065,0.022,0.128,0.047,0.195,0.063c0.079,0.019,0.159,0.026,0.239,0.037c0.074,0.01,0.147,0.024,0.221,0.027
            c0.097,0.004,0.194-0.006,0.292-0.014c0.055-0.005,0.109-0.003,0.163-0.012c0.323-0.048,0.641-0.16,0.933-0.346l34.305-21.865
            C131.967,94.755,132.296,93.271,131.583,92.152z" />
                        <circle fill="none" stroke="#ffffff" stroke-width="5" stroke-miterlimit="10" cx="109.486"
                            cy="104.353" r="32.53" />
                    </svg>
                    <h3 id="status">Success</h3>
                </div>
                <div id="lower-side">
                    <p id="message">
                        Congratulations, your request has been successfully sent.
                    </p>
                    <button type="button" class="btn btn-default" id="contBtn" data-dismiss="modal">
                        Continue
                    </button>
                </div>
            </div> -->
        </div>
    </div>
</div>

<footer id="contact" class="dk-footer">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <div class="contact-us">
                    <div class="contact-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>

                    <div class="contact-info">
                        <h3>Location</h3>
                        <p> Mekiors Pride, CS Co-working 5th Floor, Izzathnagar, Khanammet, Telangana 500084</p>
                    </div>
                </div>
                <div class="contact-us">
                    <div class="contact-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>

                    <div class="contact-info">
                        <h3>Email:</h3>
                        <p>info@propertyhunters.in</p>
                    </div>
                </div>
                <div class="contact-us">
                    <div class="contact-icon">
                        <i class="fa fa-mobile" aria-hidden="true"></i>
                    </div>

                    <div class="contact-info">
                        <h3>Call:</h3>
                        <p>+91 9010795555</p>
                    </div>
                </div>
            </div>
            <!-- End Col -->
            <div class="col-md-6">
                <div class="contact-us contact-us-last">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.9160715518965!2d78.37637769999999!3d17.463730699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e563cd78cf%3A0xc4466c5f9c1b9041!2sProperty%20Hunters!5e0!3m2!1sen!2sin!4v1666592272008!5m2!1sen!2sin"
                        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="contact-us contact-us-last">
                    <a mat-mini-fab class="facebook" href="https://www.facebook.com/prophunthyd/" target="_blank">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>

                    <a mat-mini-fab class="instagram" target="_blank" href="https://www.instagram.com/prophunthyd/">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>

                    <!-- <a mat-mini-fab class="youtube" href="https://www.youtube.com/channel/UCFzZ9V3yM81mDbRi9TnZr3A">
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                    </a> -->
                </div>
            </div>
            <!-- End Col -->
        </div>
    </div>
    <!-- End Contact Container -->
    <footer class="footer-mini">
        <div class="copyright">
            © Copyright <a href="" target="_blank"><strong><span>Property Hunters</span></strong>.</a> All Rights
            Reserved
        </div>
    </footer>
</footer>

<ul class="mob-action nav nav-fill d-sm-block d-lg-none">
    <li class="nav-item" onclick="javascript:location.href='tel:+919010795555'"><span
            class="mi mi-call action-icon"></span> Call</li>
    <li class="nav-item">
        <a class="nav-link" href="#" data-toggle="modal" data-target="#myModal">
            Enquire
        </a>
    </li>
    <li class="nav-item"
        onclick="window.open('https://api.whatsapp.com/send?phone=+919010795555&amp;text=Hi!%20I\'m%20Interested In Property Hunters, Mekiors Pride, CS Co-working 5th Floor, Izzathnagar, Khanammet, Hyderabad.%20Please%20Share me%20Details.', '_blank');">
        <span class="mi mi-whatsapp action-icon">
            <span class="path1"></span><span class="path2"></span><span class="path3"></span></span> WhatsApp
    </li>
</ul>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple" class="loader">
    <p>Loading...</p>
</ngx-spinner>