import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
// import { UsersService } from 'src/app/services/users.service';
import { ViewEditDeleteComponentDialog } from './view-edit-delete/view-edit-delete.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  students: any;
  email: any;
  subscriptions: Subscription[] = [];
  errorMessage: any;
  constructor(
    private dialog: MatDialog,
    // private userService: UsersService
  ) { }

  ngOnInit() {
    this.getAllStudents();
  }
  getAllStudents() {
    // this.userService.getStudents().subscribe(data => {
    //   this.students = data.data;
    // });
  }

  // openDialog(studentid, value) {
  //   const dialogRef = this.dialog.open(ViewEditDeleteComponentDialog, {
  //     data: {
  //       student: studentid, val: value
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     this.email = result;
  //   });
  // }


  // editDialog(studentid: any, value: any) {
  //   const dialogRef = this.dialog.open(ViewEditDeleteComponentDialog, {
  //     data: {
  //       student: studentid, val: value
  //     }
  //   });

  //   this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log(result);
  //       this.subscriptions.push(this.userService.updateStudentId(result).subscribe({
  //         next: data => {
  //           console.log(data);
  //          this.getAllStudents();
  //         },
  //         error: error => {
  //           this.errorMessage = error.message;
  //         }
  //       })
  //       );
  //     }
  //   }));
  // }
  // deleteDialog(studentid, value) {
  //   const obj = {
  //     id: studentid,
  //   };
  //   const dialogRef = this.dialog.open(ViewEditDeleteComponentDialog, {
  //     data: {
  //       student: this.students, val: value
  //     }
  //   });

  //   this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.subscriptions.push(this.userService.deleteStudentId(obj).subscribe({
  //         next: data => {
  //           this.getAllStudents();
  //         },
  //         error: error => {
  //           this.errorMessage = error.message;
  //         }

  //       })
  //       );
  //     }
  //   }));
  // }
}


