<mat-toolbar color="primary" class="header">
  <button mat-icon-button class="example-icon" (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span routerLink="/">My App</span>
  <span class="example-spacer"></span>
  <button mat-mini-fab class="example-icon favorite-icon mr-2" (click)="profile()">
    R
  </button>
  <button mat-icon-button class="example-icon" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
  </button>

</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" class="sidebar" [(opened)]="opened">
    <ul>
      <li routerLink="/backend/dashboard" routerLinkActive="active" [routerLinkActiveOptions]={exact:true}>
        <mat-icon class="mr-2">dashboard</mat-icon>
        <span>Dashboard</span>
      </li>
      <li routerLink="/backend/dashboard/customer" routerLinkActive="active">
        <mat-icon class="mr-2">supervisor_account</mat-icon>
        Customer Request Details
      </li>
      <li routerLink="/backend/dashboard/upload" routerLinkActive="active">
        <mat-icon class="mr-2">cloud_download</mat-icon>
        Upload images
      </li>
      <li routerLink="/backend/dashboard/service-gallery" routerLinkActive="active">
        <mat-icon class="mr-2"> person_pin</mat-icon>
        Add Services
      </li>
      <li routerLink="/backend/dashboard/employees" routerLinkActive="active">
        <mat-icon class="mr-2">supervisor_account</mat-icon>
        Employee Details
      </li>
      <li routerLink="/backend/dashboard/price" routerLinkActive="active">
        <mat-icon class="mr-2">supervisor_account</mat-icon>
        Price List
      </li>
    </ul>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>