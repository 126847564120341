import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  messages: string[] = [];
  private buyerSource = new BehaviorSubject(null);
  buyerMessage = this.buyerSource.asObservable();

  add(message: string) {
    this.messages.push(message);
  }

  buyerDropdown(message: boolean) {
    this.buyerSource.next(message);
  }
  clear() {
    this.messages = [];
  }
}
