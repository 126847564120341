import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  user: Observable<any> | undefined;
  constructor(
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn()) {
      const currentUser = this.authService.getCurrentUser();
      request = request.clone({
        // setHeaders: { 'x-access-token': currentUser.accessToken }
        setHeaders: { Authorization: `Bearer ${currentUser.accessToken}` }
      });
    }
    return next.handle(request);
  }

}
