import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
// declare var jsPDF: any;
import jsPDF from 'jspdf';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface PeriodicElement {
  id: number;
  days: number;
  dayOf: string;
  items: string;
  price: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, days: 2, items: 'Regular Photo', dayOf: 'Morning', price: 8000 },
  { id: 2, days: 2, items: 'Regular Video', dayOf: 'Evening', price: 7000 },
  { id: 3, days: 2, items: 'Candid Photo', dayOf: 'Fullday', price: 8000 },
  { id: 4, days: 2, items: 'Drone', dayOf: 'Morning', price: 7000 },
  { id: 5, days: 2, items: 'Album', dayOf: 'Evening', price: 6000 },
];

interface DialogData {
  worktogether: any;
}

interface Together {
  id: number;
  name: string;
  value: string;
}
@Component({
  selector: 'app-modal-dialog.',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
  @ViewChild('content', { static: true }) content: ElementRef;
  displayedColumns: string[] = ['id', 'days', 'items', 'dayOf', 'price'];
  dataSource = ELEMENT_DATA;
  worktogether: any;
  hide: boolean;

  // workTogether: Together[] = [
  //   { id: 1, name: 'Pre/Post Wedding', value: 'prewedding' },
  //   { id: 2, name: 'Wedding', value: 'wedding' },
  //   { id: 3, name: 'Maternity Shoot', value: 'maternity' },
  //   { id: 4, name: 'Seeman Hanam (body shower)', value: 'seeman' },
  //   { id: 5, name: 'Baby/Kids Photography', value: 'photography' },
  //   { id: 6, name: 'Birdays Parties ', value: 'birdays' },
  //   { id: 7, name: 'Saree and Dhoti Functions', value: 'sareeanddhoti' },
  //   { id: 8, name: 'Special Events', value: 'specialEvents' },
  //   { id: 9, name: 'Corporate Events', value: 'corporateEvents' },
  // ];
  workVideoList: Array<any> = [
    { id: 0, name: 'Regular Photo', value: 'regularPhoto' },
    { id: 1, name: 'Regular Video', value: 'rfegularVideo' },
    { id: 2, name: 'Candid Photo', value: 'candidPhoto' },
    { id: 3, name: 'Cenimatic Photo', value: 'cenimaticPhoto' },
    { id: 4, name: 'LED Screen', value: 'LedScreen' },
    { id: 5, name: 'Drone ', value: 'Drone' },
    { id: 6, name: 'Live Streaming', value: 'liveStreaming' },
    { id: 7, name: 'Pre Wedding Song', value: 'preWeddingSong' },
    { id: 8, name: 'Video Mixing', value: 'videoMixing' },
  ];
  constructor(
    private dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private userService: UsersService,
  ) {
    if (data) {
      this.worktogether = data;
    }
  }

  openPDF(): void {
    const data = document.getElementById('content');
    html2canvas(data).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      this.hide = true;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('new-file.pdf'); // Generated PDF
    });

  }

}
