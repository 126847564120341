import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddPriceComponent } from './add-price/add-price.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeModalComponent } from './employee-details/employee-modal/employee-modal.component';
import { PriceListComponent } from './price-list/price-list.component';
import { AddServicesComponent } from './service-gallery/add-services/add-services.component';
import { ServiceGalleryComponent } from './service-gallery/service-gallery.component';



const routes: Routes = [
  {
    path: '', component: DashboardComponent,
    data: { title: 'Dashboard' }
  },
  {
    path: 'customer', component: CustomerDetailsComponent,
    data: { title: 'Customer' }
  },
  {
    path: 'service-gallery', component: ServiceGalleryComponent,
    data: { title: 'ServiceGallery' }
  },
  {
    path: 'add-services', component: AddServicesComponent,
    data: { title: 'AddService' }
  },
  {
    path: 'employees', component: EmployeeDetailsComponent,
    data: { title: 'Employees' }
  },
  {
    path: 'price', component: PriceListComponent,
    data: { title: 'Price' }
  },
  {
    path: 'add-price', component: AddPriceComponent,
    data: { title: 'Add Price' }
  },
  {
    path: 'add-employee', component: EmployeeModalComponent,
    data: { title: 'Add Eployee' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
