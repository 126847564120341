<div class="dashboardContainer">
    <div class="container mt-3">
        <div class="row">
            <div class="col-lg-12">
                <mat-card>
                    Welcome to Admin
                </mat-card>
            </div>
        </div>
    </div>
</div>