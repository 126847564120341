<div class="customerContainer">
    <div class="container mt-3">
        <div class="row">
            <div class="col-lg-12">
                <div class="price-heading">
                    <div>
                        <h3>Price List</h3>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" type="submit"
                            routerLink="/backend/dashboard/add-price">Add Price
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let element;let i=index;"> {{i+1}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="sheet">
                        <th mat-header-cell *matHeaderCellDef> Sheet </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef> Price </th>
                        <td mat-cell *matCellDef="let element"> {{element.price}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-raised-button color="warn" (click)="delete(element._id)">Delete</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

        </div>
    </div>
</div>