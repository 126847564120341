import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { UsersService } from '../../../services/users.service';
import { NotificationService } from '../../../services/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-modal',
  templateUrl: './employee-modal.component.html',
  styleUrls: ['./employee-modal.component.scss']
})
export class EmployeeModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  modalForm: FormGroup;
  errors: any;
  isEdit = false;
  userId: string;
  constructor(
    private notifyService: NotificationService,
    private userService: UsersService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<EmployeeModalComponent>,
    private fb: FormBuilder
  ) {
    if (data) { this.userId = data.userId; }
  }

  ngOnInit(): void {
    if (this.userId !== '0') {
      this.subscriptions.push(this.userService.getEmployee(this.userId).subscribe((result: any) => {
        this.isEdit = true;
        return result.success ? this.modalForm.patchValue(result.user) : this.notifyService.showWarning('User not found', 'Star Wedding');
      },
        e => {
          console.log(e);
          this.notifyService.showWarning('User not found', 'Star Wedding');
          return this.errors = e.error;
        }));
    }
    this.modalForm = this.fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      gender: ['', Validators.required],
      experience: ['', Validators.required],
      role: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }
  onConfirmClick(): void {
    if (this.isEdit) {
      this.subscriptions.push(this.userService.updateEmployee(this.userId, this.modalForm.value).subscribe((result: any) => {
        return result.success ? this.dialogRef.close({ success: true, isEdit: this.isEdit })
          : this.dialogRef.close({ success: false, isEdit: this.isEdit });
      },
        e => {
          console.log(e);
          this.dialogRef.close({ success: false, isEdit: this.isEdit });
          return this.errors = e.error;
        }));
    } else {
      this.subscriptions.push(this.userService.addEmployee(this.modalForm.value).subscribe((result: any) => {
        return result.success ? this.dialogRef.close({ success: true, isEdit: this.isEdit })
          : this.dialogRef.close({ success: false, isEdit: this.isEdit });
      },
        e => {
          console.log(e);
          this.dialogRef.close({ success: false, isEdit: this.isEdit });
          return this.errors = e.error;
        }));
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
