
<h2>sdcssf</h2>
<!-- <form [formGroup]="viewForm">
    <h2 mat-dialog-title>Student Details</h2>
    <div class="heading" *ngIf="dataupdate?.val === 'delete'">
        <h4 mat-dialog-title>
            {{ message }}
            {{dataupdate.name}}
        </h4>
    </div>
    <div class="heading" *ngIf="dataupdate?.val === 'view'">
        <div>
            <h4>Student Name: {{dataupdate?.student?.name}}</h4>
            <p>Class: {{dataupdate?.student?.class}}</p>
            <p>Year: {{dataupdate?.student?.year}}</p>
        </div>
    </div>
    <div class="confirmation-container" >
        <mat-dialog-content class="dialog-container" *ngIf="dataupdate?.val === 'edit'">
            <div class="container-inside-dialog">
                <mat-form-field class="example-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Name" formControlName="name">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Class</mat-label>
                    <input matInput placeholder="Class" formControlName="class">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Year</mat-label>
                    <mat-select [(value)]="selected" formControlName="year">
                        <mat-option [value]="1">
                            2019
                        </mat-option>
                        <mat-option [value]="2">
                            2020
                        </mat-option>
                        <mat-option [value]="3">
                            2021
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1"> Yes
            </button>
            <button mat-raised-button color="warn" [mat-dialog-close] tabindex="-1">Cancel</button>
        </mat-dialog-actions>
    </div>
</form> -->