import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/services/notification.service';

export interface PeriodicElement {
  sheet: string;
  position: number;
  price: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, sheet: 'Hydrogen', price: 1.0079, },
  { position: 2, sheet: 'Helium', price: 4.0026, },
  { position: 3, sheet: 'Lithium', price: 6.941, },
  { position: 4, sheet: 'Beryllium', price: 9.0122, },
  { position: 5, sheet: 'Boron', price: 10.811, },
  { position: 6, sheet: 'Carbon', price: 12.0107, },
  { position: 7, sheet: 'Nitrogen', price: 14.0067, },
  { position: 8, sheet: 'Oxygen', price: 15.9994, },
  { position: 9, sheet: 'Fluorine', price: 18.9984, },
  { position: 10, sheet: 'Neon', price: 20.1797, },
];
@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['position', 'sheet', 'price', 'action'];
  dataSource: MatTableDataSource<PeriodicElement>;
  subscriptions: Subscription[] = [];
  errors: any;
  constructor(
    private userService: UsersService,
    private notifyService: NotificationService,
  ) { }


  ngOnInit() {
    this.getPriceList();
  }

  getPriceList(): void {
    this.userService.getPriceDetails().subscribe(result => {
      this.dataSource = new MatTableDataSource(result.priceList);
    });
  }

  delete(id: string): void {
    this.subscriptions.push(this.userService.deletePrice(id).subscribe((result: any) => {
      this.getPriceList();
      return result.success ? this.notifyService.showSuccess('Delete successfully!!', 'Star Wedding')
        : this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
    },
      e => {
        console.log(e);
        this.notifyService.showWarning('Delete Failed!!', 'Star Wedding');
        return this.errors = e.error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
