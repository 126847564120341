import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { environment } from '../../environments/environment';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private apiUrl = `${environment.apiUrl}`;
    private handleError: HandleError;
    params: Observable<any>;
    public paramsSource: BehaviorSubject<any>;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        private httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = this.httpErrorHandler.createHandleError('UsersService');
        this.paramsSource = new BehaviorSubject({});
        this.params = this.paramsSource.asObservable();
    }

    setParams(params: Params): void {
        this.paramsSource.next(params);
    }

    fileUpload(fileData: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/api/file-upload`, fileData);
    }
    imgDelete(key: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/api/file-delete`, key);
    }

    allImagesUpload(key: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/api/images-upload`, key);
    }
}
