import { Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router, Event } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CommonService } from './services/common.service';
import { Subscription, combineLatest } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private routerHelper: CommonService,
    private titleService: Title
  ) { }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {
    this.elementRef.nativeElement.removeAttribute('ng-version');
    const appTitle = this.titleService.getTitle();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map(route => {
        const params = [];
        params.push(route.params);

        while (route.firstChild) {
          route = route.firstChild;
          params.push(route.params);
        }

        const ttl = route.snapshot.data.title;
        if (ttl) {
          this.titleService.setTitle(ttl);
        } else {
          this.titleService.setTitle(appTitle);
        }
        return combineLatest(params);
      }))
      .pipe(mergeMap(params => params))
      .pipe(map(params => params.reduce((prev, curr) => {
        Object.keys(curr).forEach(key => {
          prev[key] = curr[key];
        });

        return prev;
      }, {})))
      .subscribe(p => {
        this.routerHelper.setParams(p);
      });
  }
}
